/* todo? all margins except right important? */

.u1-flex-gap {
    display:flex;
    flex-wrap:wrap;
    --u1-Gap:var(--gap, 1rem);
    --u1-Row-gap:var(--u1-Gap);
    --u1-Col-gap:var(--u1-Gap);
    margin:
        calc( var(--u1-Row-gap) * -.5 )
        0
        calc( var(--u1-Row-gap) * -.5 )
        calc( var(--u1-Col-gap) * -1 );
}
.u1-flex-gap > * {
    margin:
        calc( var(--u1-Row-gap) * .5 )
        0
        calc( var(--u1-Row-gap) * .5 )
        var(--u1-Col-gap);
}
